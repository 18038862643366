import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'home',
    component: () => import('../views/IndexView.vue'),
    meta: {
      title: '首页 / 碎碎念',
      keepAlive: true,
    }
  },
  /**
   * 分类列表
   */
  {
    path: '/category',
    component: () => import('../views/CategoryView.vue'),
    meta: {
      title: '分类 / 碎碎念',
      keepAlive: true
    }
  },
  /**
   * 分类详情
   * 根据 categoryName 查询
   */
  {
    path: '/category/:categoryName',
    name: 'category-list',
    component: () => import('../views/CategoryList.vue'),
    meta: {
      title: '分类 / 碎碎念',
      keepAlive: true
    }
  },
  /**
   * 文章详情
   */
  {
    path: '/article/:articleId',
    alias: '/search?q=:id&src=:fromUrl',
    name: 'article-detail',
    component: () => import('../views/ArticleDetail.vue'),
    meta: {
      title: '文章详情 / 碎碎念',
      keepAlive: true
    }
  },
  /**
   * 登录页
   */
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: {
      title: '碎碎念',
      keepAlive: true
    }
  },
  /**
   * 关于我们
   */
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: {
      title: '关于我们 / 碎碎念',
      keepAlive: true
    }
  },
  /**
   * 友情链接
   */
  {
    path: '/friendslink',
    name: 'friendslink',
    component: () => import('../views/FriendsLink.vue'),
    meta: {
      title: '友情链接 / 碎碎念',
      keepAlive: true
    }
  },
  // 如果是其他用户信息 -> 偏向于 /:uid/personal
  // 但现在只设置了自己的个人信息 所以是 /personal 
  {
    path: '/personal',
    name: 'personal',
    component: () => import('../views/user/PersonalView.vue'),
    meta: {
      title: '个人中心 / 碎碎念',
    }
  },
  {
    path: '/u/:username',
    name: 'user-page',
    component: () => import('../views/user/UserPage.vue'),
    meta: {
      title: '个人中心 / 碎碎念',
    }
  },
  // 热搜榜
  {
    path: '/explore',
    name: 'explore',
    component: () => import('../views/HotArticleList.vue'),
    meta: {
      title: '热门文章 / 碎碎念'
    }
  },
  {
    path: '*',
    alias: '/404',
    component: () => import('../views/404.vue'),
    meta: {
      title: '啊哦，走丢咯 / 碎碎念'
    }
  },
  {
    path: '/doc',
    component: () => import('../views/Document.vue'),
    meta: {
      title: '文档 / 碎碎念'
    }
  }
]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  //路由发生改变时,触发
  // 设置页面标题
  window.document.title = undefined ? '碎碎念' : to.meta.title;
  next();
});

export default router
